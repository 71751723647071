import { FormikProps } from "formik";
import { ReactComponent as Email } from "@assets/icons/mail.svg";
import { FaUser } from "react-icons/fa";
import { BsCalendarDate } from "react-icons/bs";
import { ImManWoman } from "react-icons/im";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { SignUpFormValues } from "../types/Types";
import { useEffect, useState } from "react";
import { ReactComponent as Eye } from "@assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "@assets/icons/eye-off.svg";
import { hasError } from "@src/utils/FormikFunctions";
import BaseModal from "@src/components/BaseModal/BaseModal";
import { getAge } from "@src/utils/Dates";

type StepOneProps = {
	formik: FormikProps<SignUpFormValues>;
};

type ActiveModal = "male" | "minor" | null;

const StepOne = ({ formik }: StepOneProps) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [activeModal, setActiveModal] = useState<ActiveModal>(null);

	const hideAlert = () => setActiveModal(null);

	useEffect(() => {
		if (formik.values.gender === "Hombre") {
			setActiveModal("male");
		}
	}, [formik.values.gender]);

	useEffect(() => {
		if (formik.values.birthdate) {
			const age = getAge(formik.values.birthdate);

			if (age < 18) {
				// If another modal is already active and you want to prioritize one,
				// you can implement a priority check here.
				setActiveModal("minor");
			}
		}
	}, [formik.values.birthdate]);

	return (
		<>
			<BaseModal title={"Hola"} show={activeModal !== null} size="lg" onHide={hideAlert}>
				<div className="d-flex flex-column">
					{activeModal === "male" && (
						<p className="fs-16 fw-medium ">
							Gracias por elegirnos como tu opción para ejercitarte. Recuerda que nuestra única sucursal Mixta
							es Río Sonora.
							<br />
							<br />
							Deberás seleccionar esa sucursal para poder hacer uso de tus reservas.
						</p>
					)}
					{activeModal === "minor" && (
						<p className="fs-16 fw-medium ">
							Para entrenar en Wellness|Studio, la edad mínima permitida es 15 años.
							<br />
							<br />
							Todo menor de edad deberá llenar un formato especial en la recepción de cada sucursal.
						</p>
					)}
					<button className="btn primary mt-4" type="button" onClick={hideAlert}>
						Entiendo
					</button>
				</div>
			</BaseModal>
			<div className="form-row">
				<label htmlFor="email">Correo</label>
				<div className="input-container">
					<label htmlFor="email">
						<Email />
					</label>
					<input
						id="email"
						name="email"
						type="email"
						placeholder="Correo"
						onChange={formik.handleChange}
						value={formik.values.email}
						aria-invalid={hasError(formik, "email") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="password">Contraseña</label>
				<div className="input-container">
					<label htmlFor="password" draggable={false}>
						{showPassword ? (
							<EyeOff onClick={() => setShowPassword(false)} />
						) : (
							<Eye onClick={() => setShowPassword(true)} />
						)}
					</label>
					<input
						id="password"
						name="password"
						type={showPassword ? "text" : "password"}
						placeholder="Contraseña"
						value={formik.values.password}
						onChange={formik.handleChange}
						aria-invalid={hasError(formik, "password") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="confirmPassword">Confirmar Contraseña</label>
				<div className="input-container">
					<label htmlFor="confirmPassword">
						{showConfirmPassword ? (
							<EyeOff onClick={() => setShowConfirmPassword(false)} />
						) : (
							<Eye onClick={() => setShowConfirmPassword(true)} />
						)}
					</label>
					<input
						id="confirmPassword"
						name="confirmPassword"
						type={showConfirmPassword ? "text" : "password"}
						placeholder="Confirmar Contraseña"
						value={formik.values.confirmPassword}
						onChange={formik.handleChange}
						aria-invalid={hasError(formik, "confirmPassword") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="name">Nombre(s)</label>
				<div className="input-container">
					<label htmlFor="name">
						<FaUser />
					</label>
					<input
						id="name"
						name="name"
						type="name"
						placeholder="Nombre(s)"
						onChange={formik.handleChange}
						value={formik.values.name}
						aria-invalid={hasError(formik, "name") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="lastName">Apellido(s)</label>
				<div className="input-container">
					<label htmlFor="lastName">
						<FaUser />
					</label>
					<input
						id="lastName"
						name="lastName"
						type="text"
						placeholder="Apellido(s)"
						onChange={formik.handleChange}
						value={formik.values.lastName}
						aria-invalid={hasError(formik, "lastName") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="birthdate">Fecha de nacimiento</label>
				<div className="input-container">
					<label htmlFor="birthdate">
						<BsCalendarDate />
					</label>
					<input
						id="birthdate"
						name="birthdate"
						type="date"
						placeholder="Fecha de nacimiento"
						onChange={formik.handleChange}
						value={formik.values.birthdate}
						aria-invalid={hasError(formik, "birthdate") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="phone">Teléfono</label>
				<div className="input-container">
					<label htmlFor="phone">
						<HiDevicePhoneMobile />
					</label>
					<input
						id="phone"
						name="phone"
						type="tel"
						placeholder="Teléfono"
						value={formik.values.phone}
						onChange={formik.handleChange}
						aria-invalid={hasError(formik, "phone") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="gender">Género</label>
				<div className="input-container">
					<label htmlFor="gender">
						<ImManWoman />
					</label>
					<select
						id="gender"
						name="gender"
						value={formik.values.gender}
						onChange={formik.handleChange}
						aria-invalid={hasError(formik, "gender") ? "true" : "false"}
					>
						<option value="">Selecciona</option>
						<option value={"Mujer"}>Mujer</option>
						<option value={"Hombre"}>Hombre</option>
					</select>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
		</>
	);
};

export default StepOne;
